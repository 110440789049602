// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import '../../../node_modules/ng-zorro-antd/ng-zorro-antd.less';

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@primary-color: #6e54fc;
@accent-color: #000000;
@warning-color: #ffcd1c;
@error-color: #fc4e69;

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border: 1.3px solid #6d54fc !important;
  border-radius: 5px 0px 0px 5px !important;
  background-color: white;
  height: 40px;
}

.ant-form-item-explain,
.ant-form-item-extra {
  color: #02a573;
  font-size: 12px;
  line-height: 20px;
}
