@import '../../../libs/shared/src/lib/utils/utils';
@import '../../../libs/shared/src/lib/utils/fonts';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif !important;
}

.primary-color {
  color: #6e54fc !important;
}

.accent-color {
  color: #000000;
}

.warning-color {
  color: #ffcd1c;
}

.error-color {
  color: #fc4e69;
}

.defaultModal .ant-modal-content {
  min-height: 100vh;
}

// To onboarding modal.
.ant-notification {
  z-index: 999;
}

p {
  b,
  strong {
    font-family: 'Poppins-Bold';
  }

  a {
    font-family: 'Poppins-Semibold';
  }
}

h1,
h2,
h3 {
  font-family: 'Poppins';
}

app-secondary-button {
  .ant-btn {
    height: 48px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    color: #6e54fc;
    border: 1px solid #6e54fc;
  }
}

app-primary-button {
  .ant-btn-primary {
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    height: 48px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
  }

  ::ng-deep {
    .ant-btn[disabled] {
      background-color: #6d54fc;
      color: white;
      opacity: 40%;

      &:hover {
        background-color: #6d54fc;
        color: white;
        opacity: 40%;
      }
    }
  }
}
